import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { Link } from 'gatsby';

const CookieBanner = () => {
  const [accepted, setAccepted] = useState(() => {
    return Cookies.get("accepted") === "true"
  })

  const handleAccept = () => {
    Cookies.set("accepted", "true", { expires: 7 })
    setAccepted(true)
  }

  const handleDecline = () => {
    Cookies.set("accepted", "false", { expires: 7 })
    setAccepted(false)
  }

  useEffect(() => {
    const banner = document.querySelector('.cookie-banner')
    if (accepted && banner) {
      banner.style.display = 'none'
    } else if (!accepted && banner) {
      banner.style.display = 'flex'
    }
  }, [accepted])
  

  return (
    <div className={`cookie-banner ${accepted ? "accepted" : "not-accepted"}`}>
      <p>
        This website uses cookies to ensure you get the best experience on our website.  |
        <a href="/PrivacyPolicy">Learn More|</a>
      </p>
      {!accepted && (
        <div className="buttons" style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={handleAccept}>Accept</button>
          <button onClick={handleDecline}>Decline</button>
        </div>
      )}
    </div>
  )
}

export default CookieBanner