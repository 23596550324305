import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Burger from './Burger';
import FKLogoSmall from './LogoWhiteSmall.png';

const Nav = styled.nav`
  position: fixed;
  background: #5a5a57;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  color: #F5F6F7;

  .logo {
    padding: 10px 0;

    a {
      color: #F5F6F7;
      text-decoration: none;
    }
  }

  .image1 {
    position: absolute;
    margin-top: 17px;
    z-index: 100;
  }

  .image2 {
    display: none;
    position: absolute;
    margin-top: 17px;
    z-index: 100;
  }

  @media (max-width: 768px) {
    .image1 {
      display: none;
    }

    .image2 {
      display: block;
      position: absolute;
      margin-top: 35px;
      left: 20px;
      top: -8px;
    }
  }

  @media (max-width: 360px) {
    .image1 {
      display: none;
    }

    .image2 {
      display: block;
      position: absolute;
      margin-top: 38px;
      left: 20px;
      top: -8px;
      height: 35px;
      width: auto;
      background-image: url(${FKLogoSmall});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  @media (max-width: 359px) {
    .image1 {
      display: none;
    }

    .image2 {
      display: block;
      position: absolute;
      margin-top: 38px;
      left: 20px;
      top: -8px;
      height: 26px;
      width: auto;
      background-image: url(${FKLogoSmall});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query MyQuery1 {
      allContentfulNavigationMenu {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const logoUrl = data.allContentfulNavigationMenu.edges[0].node.image.image.file.url;

  return (
    <Nav>
      <div className="logo">
        <Link to='/'>
          <img className="image1" src={logoUrl} alt="Logo" />
        </Link>
      </div>
      <div className="logo2">
        <Link to='/'>
          <img className="image2" src={logoUrl} alt="Logo" />
        </Link>
      </div>
      <Burger />
    </Nav>
  );
};

export default Navbar;
