// import React, { useState, useEffect } from "react"
// import "./scss/FeatureBarPink.scss"
// import { graphql, useStaticQuery } from "gatsby"

// export default function FeatureBarPink() {
//   const [isVisible, setIsVisible] = useState(true);
//   const [typedText, setTypedText] = useState(""); // Store the currently typed text
//   const typingSpeed = 50; // Adjust the typing speed in milliseconds (lower value for faster typing)
//   const [isOriginalText, setIsOriginalText] = useState(true);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       if (scrollPosition > 800) {
//         setIsVisible(false);
//       } else {
//         setIsVisible(true);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const switchTextTimeout = setTimeout(() => {
//       setIsOriginalText((prevIsOriginalText) => !prevIsOriginalText);
//     }, 4000); // 2000 milliseconds = 2 seconds

//     return () => {
//       clearTimeout(switchTextTimeout);
//     };
//   }, [isOriginalText]);

//   const data = useStaticQuery(graphql`
//     query MyQuery6 {
//       allContentfulUspBar {
//         edges {
//           node {
//             uspText
//           }
//         }
//       }
//     }
//   `);

//   const uspText = data.allContentfulUspBar.edges[0].node.uspText;
//   const currentText = isOriginalText ? uspText : "Find New Customers & then to New Markets";

//   useEffect(() => {
//     let index = 0;
//     let timer;

//     if (isVisible) {
//       setTypedText(""); // Clear the typed text when the component becomes visible again
//       timer = setInterval(() => {
//         setTypedText((prevText) => prevText + currentText.charAt(index));
//         index++;
//         if (index === currentText.length) {
//           clearInterval(timer);
//         }
//       }, typingSpeed);
//     }

//     return () => {
//       clearInterval(timer); // Clear the interval when the component is unmounted or isVisible changes
//     };
//   }, [isVisible, currentText]);

//   return (
//     <div>
//       <nav className={`featureBarPink${isVisible ? '' : ' fade-out'}`}>
//         <div className="text">
//           {typedText}
//         </div>
//       </nav>
//     </div>
//   )
// }





import React, { useState, useEffect } from "react";
import "./scss/FeatureBarPink.scss";
import { graphql, useStaticQuery } from "gatsby";

export default function FeatureBarPink() {
  const [isVisible, setIsVisible] = useState(true);
  const [isOriginalText, setIsOriginalText] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 800) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const switchTextTimeout = setTimeout(() => {
      setIsOriginalText((prevIsOriginalText) => !prevIsOriginalText);
    }, 1500);

    return () => {
      clearTimeout(switchTextTimeout);
    };
  }, [isOriginalText]);

  const data = useStaticQuery(graphql`
    query MyQuery6 {
      allContentfulUspBar {
        edges {
          node {
            uspText
          }
        }
      }
    }
  `);

  const uspText = data.allContentfulUspBar.edges[0].node.uspText;
  const currentText = isOriginalText
    ? uspText
    : "Scientia est Potentia - Knowledge is Power";

  return (
    <div>
      <nav className={`featureBarPink${isVisible ? '' : ' fade-out'}`}>
        <div className="text">
          {currentText}
        </div>
      </nav>
    </div>
  );
}

