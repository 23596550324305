import React, { useState } from 'react'
import { graphql, useStaticQuery } from "gatsby"
import "./scss/NewsletterBar.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import emailIcon from './email-icon.png';
import { StaticImage } from "gatsby-plugin-image"
// import { navigate } from 'gatsby';

export default function NewsletterBar() {
  const data = useStaticQuery(graphql`
  query MyQuery17 {
    allContentfulNewsletterBar(filter: { id: { eq: "2387c8c7-0827-5c11-8625-0f741b3f6eb0" } }) {
      edges {
        node {
          title
          id
          bodyText {
            raw
          }
          callToAction
          emailPlaceholder {
            microcopyText
          }
          image {
            image {
              file {
                url
              }
            }
            altText
          }
          radioButtonText1
          radioButtonText2
          radioButtonText3
          termsAndConditionsText {
            raw
          }
        }
      }
    }
  }
`);


  const desiredNode = data.allContentfulNewsletterBar.edges[0].node;
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [isEmailInputEnabled, setIsEmailInputEnabled] = useState(false);

  const handleEmailInputChange = (e) => {
    if (errorMessage) {
      setErrorMessage(''); // Clear the error message when the user starts typing a different email
    }
  };

  // const isEmailAllowed = (email) => {
  //   const emailDomain = email.split('@')[1];
  //   const disallowedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'icloud.com']; // Add more domains if needed
  //   return !disallowedDomains.includes(emailDomain);
  // };

// ... (existing code)

const validateEmail = (email) => {
  const enteredEmail = email.trim().toLowerCase();
  
  // Disallowed email domains
  const disallowedDomains = ['@gmail', '@yahoo', '@icloud', '@aol', '@mac', '@hotmail', '@outlook', '@proton', '@protonmail', '@zohocorp', '@gmx', '@yandex', '@hubspot', '@neo', '@thunder', '@degcos', '@me'];
  
  // Disallowed email prefixes
  const disallowedPrefixes = ['info', 'contact'];

  // Check if email starts with a disallowed prefix (e.g., "info@" or "contact@")
  const emailPrefix = enteredEmail.split('@')[0];
  if (disallowedPrefixes.includes(emailPrefix)) {
    return false;
  }

  // Check if the email contains a disallowed domain
  return !disallowedDomains.some(domain => enteredEmail.includes(domain));
};


const handleSubmit = (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  const email = formData.get('MERGE0');
  console.log("Selected Radio Button Value: ", selectedRadioValue);
  console.log("Email: ", email);

  // Validate the email
  const isValidEmail = validateEmail(email);

  if (!isValidEmail) {
    setErrorMessage('Please use your business or educational email');
    console.error('Disallowed email domain used:', email);
    return;
  }

  // If validation passes, proceed with form submission
  setErrorMessage('');
  console.log('Form submitted successfully!');
  e.target.submit(); // Manually trigger form submission
  // navigate('/newsletter/thankyou/', { state: { userEmail: email } });
};

// ... (rest of the code)



  const handleRadioChange = (e) => {
    setSelectedRadioValue(e.target.value);
    setIsEmailInputEnabled(true);
  }

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <div className="newsletterContainer">
      <div className="imageContainerNewsletter">
        {/* <img
          className="bigImageNL"
          alt='ok'
          src={desiredNode.image.image.file.url}
        /> */}
        <StaticImage className={"bigImageNL"} 
        src="https://images.ctfassets.net/x5jqyuhgi4rx/1eQv0VNVkC0zFAjqQbFEhz/2d850ff5d7b88257bf86d9d5beaa7447/footer_marquee.webp"
        alt="ok"/>
      </div>
      <div className="bottomNL">
        <div className="textContainerNewsLetter">
          <div className="newsletterTitle">
            <p className="titleNL">Make Smarter Luxury Business and Investment Decisions</p>
          </div>
          <div className="newsletterBody">
            <p className="bodyTextNL">
            Sign up to the <strong>ŁUXE</strong> Newsletter.
            </p>
            <br></br>
            <p className="bodyTextNL">
            Insiders Opinion on Luxury Digital Transformation.
            </p>
            <br></br>
            <p className="bodyTextNL">
            Technical Guides for the Luxury C-Suite and Investors in your inbox once a month 
            </p>
          </div>
          <div className="signUpSection">
            <form action="https://thefreshkid.us8.list-manage.com/subscribe/post" method="POST" target="_blank" onSubmit={handleSubmit}>
              <input type="hidden" name="u" value="8abd2b2ed117ea16fd4a66f6d" />
              <input type="hidden" name="id" value="924bba4fbd" />

              <div className="NewsletterFirstRow">
                <div className="NewsletterNameContainer">
                  <div className="NewsletterTitleBox">
                    <label className="TitleLabels" htmlFor="title">
                      *Title:{" "}
                    </label>
                    {/* <label className="select">Select... </label> */}
                    <br></br>
                    <select name="MMERGE4" id="title" required>
                      <option value=""></option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms">Ms</option>
                    </select>
                  </div>
                  <div className="NewsletterFirstName">
                    <label className="FirstNameLabels" htmlFor="firstname">
                      *First Name:
                    </label>
                    <br></br>
                    <input type="text" name="FNAME" required />
                  </div>
                  <div className="NewsletterLastName">
                    <label className="LastNameLabels" htmlFor="lastname">
                      *Last Name:
                    </label>
                    <br></br>
                    <input type="text" name="LNAME" required />
                  </div>
                </div>
              </div>

              <div className="NewsletterSecondRow">
                <div className="NewsletterEmailContainer">
                  <div className="NewsletterBEName">
                    <label className="BEEmailNameLabels" htmlFor="email">
                      *Business or Educational
                    </label>
                    <div classname="InstituteName"> 
                      <p className="IN">Institute Name</p>
                    </div>
                    <br></br>
                    <input type="text" name="MMERGE5" required />
                  </div>
                  <div className="NewsletterEmailName">
                    <label className="BEEmailLabels" htmlFor="lastname">
                      *Business or Educational
                    </label>
                    <div classname="InstituteEmail"> 
                      <p className="IE">Institute Email</p>
                    </div>
                    <br></br>
                    <input type="email" name="MERGE0" id="emailInput" className="NewsletterEmail" onChange={handleEmailInputChange} required />
                  </div>
                </div>
              </div>

              <div className="radioSelectionNL">
                <label className="radioButtonsNL1">
                  <span className="radioInput">
                    <input type="radio" id="man" name="MMERGE3" value={desiredNode.radioButtonText1} onChange={handleRadioChange} />
                    <span className="radioControl"></span>
                  </span>
                  <span className="radioLabel">{desiredNode.radioButtonText1}</span>
                </label>
                <label className="radioButtonsNL2">
                  <span className="radioInput">
                    <input type="radio" id="woman" name="MMERGE3" value={desiredNode.radioButtonText2} onChange={handleRadioChange} />
                    <span className="radioControl"></span>
                  </span>
                  <span className="radioLabel">{desiredNode.radioButtonText2}</span>
                </label>
                <label className="radioButtonsNL3">
                  <span className="radioInput">
                    <input type="radio" id="othero" name="MMERGE3" value= "Luxury Student" onChange={handleRadioChange} />
                    <span className="radioControl"></span>
                  </span>
                  <span className="radioLabel">Luxury Student</span>
                </label>
                <label className="radioButtonsNL4">
                  <span className="radioInput">
                    <input type="radio" id="othero" name="MMERGE3" value={desiredNode.radioButtonText3} onChange={handleRadioChange} />
                    <span className="radioControl"></span>
                  </span>
                  <span className="radioLabel">{desiredNode.radioButtonText3}</span>
                </label>
              </div>
              
              {/* <input type="email" name="MERGE0" id="emailInput" className="emailInput" onChange={handleEmailInputChange} placeholder={desiredNode.emailPlaceholder.microcopyText} required disabled={!isEmailInputEnabled} /> */}

              {/* <button type="submit" className="callToActionNL" disabled={!isEmailInputEnabled}>
                <img src={emailIcon} alt="Email Icon" className="emailIcon" />
              </button> */}
                <div className="NewsletterSubmit">
                    <input type="submit" value="Sign Up to Luxe" />
                </div>
              {errorMessage && <p className="errorr-message">{errorMessage}</p>}
            </form>
          </div>

          <div className="newsletterTerms">
            <p className="termsNL">
              * Required Information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By subscribing, you agree to our <a href="/PrivacyPolicy" class="privacy-links">Privacy & Cookies Policy</a>
            </p>
          </div>
          <div className="newsletterTermsMediaQuery650">
            <p className="termsNL650">
              * Required Information<br></br>By subscribing, you agree to our <a href="/PrivacyPolicy">Privacy & Cookies Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}